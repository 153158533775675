import React, { useEffect, useState } from "react";
import ComponentCustomModal from "../../../components/common/customModal/ComponentCustomModal";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup"; // Assuming you're using Yup for validation
import { FtextField } from "../../../components/common/FtextField";
import Loader from "../../../components/common/Loader";
import {
  getNetworkDevice,
  networkDeviceAssign,
} from "../../../features/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import useISPowner from "../../../hooks/useISPOwner";

const AssignDevice = ({ show, setShow, data }) => {
  console.log(data);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // get user & current user data form useISPOwner hooks
  const { ispOwnerId } = useISPowner();

  // get network devices form redux store
  const devices = useSelector((state) => state.network?.devices);

  // Loading state for form submission
  const [isLoading, setIsLoading] = useState(false);

  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    getNetworkDevice(dispatch, ispOwnerId, setIsLoading);
  }, [data]);

  // Define input fields with properties
  const inputOptions = [
    {
      as: "select",
      id: "candidateId",
      name: "candidateId",
      label: `${t("network")} ${t("device")}`,
      firstOptions: t("selectDeviceType"),
      options: devices,
      textAccessor: "name",
      valueAccessor: "id",
      validation: true,
      isVisible: true,
      disabled: false,
    },
    {
      type: "text",
      id: "location",
      name: "location",
      label: t("device location"),
      validation: true,
      isVisible: true,
      disabled: false,
    },
  ];

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    candidateId: Yup.string().required(t("requiredField")),
    location: Yup.string().required(t("requiredField")),
    remarks: Yup.string(),
  });

  // Handle form submission
  const deviceAssignHandler = async (values) => {
    const assignData = {
      ...values,
      remarks,
      ispOwner: ispOwnerId,
      parentPath: data?.details.parentPath
        ? `${data?.details.parentPath}/${data?.details.id}`
        : `/${ispOwnerId}`,
      parentId: data?.details.id,
    };

    networkDeviceAssign(dispatch, assignData, setIsLoading, setShow);
  };

  return (
    <ComponentCustomModal
      show={show}
      setShow={() => setShow(false)}
      centered
      size="md"
      header={`${data?.name} ${t("assign")} ${t("device")}`}
      footer={
        <div className="displayGrid1 float-end">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShow(false)}
          >
            {t("cancel")}
          </button>
          <button
            type="submit"
            form="assignDeviceForm"
            className="btn btn-success"
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : t("submit")}
          </button>
        </div>
      }
    >
      <Formik
        initialValues={{
          candidateId: "",
          location: "",
          remarks: "",
        }}
        validationSchema={validationSchema}
        onSubmit={deviceAssignHandler}
      >
        {() => (
          <Form id="assignDeviceForm">
            <div className="displayGrid2 mb-3">
              {inputOptions?.map(
                (item) => item?.isVisible && <FtextField {...item} />
              )}
            </div>

            <textarea
              class="form-control"
              name="remarks"
              rows="3"
              placeholder="Note"
              onChange={(e) => setRemarks(e.target.value)}
              value={remarks}
            ></textarea>
          </Form>
        )}
      </Formik>
    </ComponentCustomModal>
  );
};

export default AssignDevice;
