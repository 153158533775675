import React, { useEffect, useState } from "react";
import ComponentCustomModal from "../../../components/common/customModal/ComponentCustomModal";
import { ErrorMessage, Field, Form, Formik, FieldArray } from "formik";
import { FtextField } from "../../../components/common/FtextField";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/common/Loader";
import { InputGroup, Table } from "react-bootstrap";
import * as Yup from "yup";
import { createNetworkDevice } from "../../../features/apiCalls";
import { useDispatch } from "react-redux";
import useISPowner from "../../../hooks/useISPOwner";

const DeviceForm = ({ show, setShow, title }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // get user & current user data form useISPOwner hooks
  const { ispOwnerId } = useISPowner();

  const [isLoading, setIsLoading] = useState(false);
  const [portItems, setPortItems] = useState([]);

  // Validation schema for Formik
  const validationSchema = Yup.object().shape({
    ratio: Yup.number()
      .required("Port ratio is required")
      .min(0, "Port ratio must be greater than 0")
      .max(50, "Port ratio cannot exceed 50"),
    outputs: Yup.array().of(
      Yup.object().shape({
        portName: Yup.string().required("Port name is required"),
      })
    ),
  });

  const inputOption = [
    {
      as: "select",
      id: "candidateType",
      name: "candidateType",
      label: t("deviceType"),
      firstOptions: t("selectDeviceType"),
      textAccessor: "name",
      valueAccessor: "value",
      options: [
        {
          name: t("mikrotik"),
          value: "mikrotik",
        },
        {
          name: t("olt"),
          value: "olt",
        },
        {
          name: t("switch"),
          value: "switch",
        },
        {
          name: t("splitter"),
          value: "splitter",
        },
        {
          name: t("onu"),
          value: "onu",
        },
      ],
      validation: true,
      isVisible: true,
      disabled: false,
    },
    {
      type: "text",
      id: "name",
      name: "name",
      label: t("name"),
      validation: true,
      isVisible: true,
      disabled: false,
    },
    {
      type: "text",
      id: "brand",
      name: "brand",
      label: t("brand"),
      validation: false,
      isVisible: true,
      disabled: false,
    },
    {
      type: "text",
      id: "model",
      name: "deviceModel",
      label: t("model"),
      validation: false,
      isVisible: true,
      disabled: false,
    },
    {
      type: "text",
      id: "ip",
      name: "ip",
      label: t("ip"),
      validation: true,
      isVisible: true,
      disabled: false,
    },
  ];

  useEffect(() => {
    if (portItems.length === 0) {
      setPortItems([]); // Initial empty array
    }
  }, []);

  // Function to dynamically generate ports based on watchPort value
  const generatePortItems = (watchPort) => {
    let newPortItems = [];
    if (Number(watchPort) > 0 && Number(watchPort) <= 50) {
      for (let i = 0; i < Number(watchPort); i++) {
        newPortItems.push({
          serial: i + 1,
          portName: "",
          portPower: "",
          color: "",
        });
      }
    }
    return newPortItems;
  };

  return (
    <>
      <ComponentCustomModal
        show={show}
        setShow={setShow}
        centered={true}
        size="lg"
        header={title}
      >
        <Formik
          initialValues={{
            candidateType: "",
            type: "",
            brand: "",
            deviceModel: "",
            ip: "",
            ratio: "",
            outputs: [],
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const deviceData = {
              ...values,
              ispOwner: ispOwnerId,
            };

            await createNetworkDevice(
              dispatch,
              deviceData,
              setIsLoading,
              setShow
            );
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form id="networkDevice">
              <div className="displayGrid2 mb-3">
                {inputOption?.map(
                  (item) => item?.isVisible && <FtextField {...item} />
                )}

                {/* Port ratio input */}
                <div className="form-group">
                  <label className="changeLabelFontColor">
                    {t("port")} <span className="text-danger me-4">*</span>
                  </label>
                  <Field
                    type="number"
                    name="ratio"
                    className="form-control"
                    placeholder="Enter number of ports"
                    onChange={(e) => {
                      handleChange(e);
                      const updatedPorts = generatePortItems(e.target.value);
                      setFieldValue("outputs", updatedPorts);
                    }}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="ratio"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              {/* Port inputs dynamically generated */}
              <FieldArray
                name="outputs"
                render={() => (
                  <>
                    {values.outputs && values.outputs.length > 0 && (
                      <Table>
                        <thead>
                          <tr>
                            <th>Sl</th>
                            <th>Port Name</th>
                            <th>Port Power %</th>
                            <th>Color</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.outputs.map((port, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <Field
                                  name={`outputs[${index}].portName`}
                                  className="form-control"
                                  placeholder="Enter Port Name"
                                />
                                <ErrorMessage
                                  name={`outputs[${index}].portName`}
                                  component="div"
                                  className="text-danger"
                                />
                              </td>

                              <td>
                                <Field
                                  name={`outputs[${index}].portPower`}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Port Power"
                                />
                              </td>

                              <td>
                                <Field
                                  name={`outputs[${index}].color`}
                                  as="select"
                                  className="form-select mt-0"
                                >
                                  <option value="mikrotik">
                                    {t("mikrotik")}
                                  </option>
                                  <option value="olt">{t("olt")}</option>
                                  <option value="switch">{t("switch")}</option>
                                  <option value="splitter">
                                    {t("splitter")}
                                  </option>
                                  <option value="onu">{t("onu")}</option>
                                </Field>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </>
                )}
              />

              {/* Submit and cancel buttons */}
              <div className="displayGrid1 float-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShow(false)}
                >
                  {t("cancel")}
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isSubmitting}
                >
                  {isLoading ? <Loader /> : t("submit")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ComponentCustomModal>
    </>
  );
};

export default DeviceForm;
