import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../../components/admin/sidebar/Sidebar";
import { ToastContainer } from "react-toastify";
import useDash from "../../../assets/css/dash.module.css";
import { FontColor, FourGround } from "../../../assets/js/theme";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/common/Loader";
import { ArrowClockwise, Plus } from "react-bootstrap-icons";
import DeviceForm from "./DeviceForm";
import { getNetworkDevice } from "../../../features/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import useISPowner from "../../../hooks/useISPOwner";
import Table from "../../../components/table/Table";
import moment from "moment";
import Footer from "../../../components/admin/footer/Footer";

const Device = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // get user & current user data form useISPOwner hooks
  const { ispOwnerId } = useISPowner();

  // get network devices form redux store
  const devices = useSelector((state) => state.network?.devices);

  // Loading state
  const [isLoading, setIsLoading] = useState(false);

  // Modal handle state
  const [show, setShow] = useState(false);
  const [modalStatus, setModalStatus] = useState("");

  // Modal form handle state
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    getNetworkDevice(dispatch, ispOwnerId, setIsLoading);
  }, []);

  const deviceReloadHandler = () => {
    getNetworkDevice(dispatch, ispOwnerId, setIsLoading);
  };

  const columns = useMemo(
    () => [
      {
        width: "5%",
        Header: t("serial"),
        id: "row",
        accessor: (row) => Number(row.id + 1),
        Cell: ({ row }) => <strong>{Number(row.id) + 1}</strong>,
      },
      {
        width: "10%",
        Header: t("deviceType"),
        accessor: "candidateType",
      },
      {
        width: "10%",
        Header: t("device"),
        accessor: "name",
      },
      {
        width: "10%",
        Header: t("ip"),
        accessor: "ip",
      },
      {
        width: "10%",
        Header: t("brand"),
        accessor: "brand",
      },
      {
        width: "10%",
        Header: t("model"),
        accessor: "deviceModel",
      },
      {
        width: "10%",
        Header: t("date"),
        accessor: "createdAt",
        Cell: ({ cell: { value } }) => {
          return moment(value).format("YYYY/MM/DD hh:mm a");
        },
      },
    ],
    [t]
  );

  return (
    <>
      <Sidebar />
      <ToastContainer position="top-right" theme="colored" />
      <div className={useDash.dashboardWrapper}>
        <div className="container-fluied collector">
          <div className="container">
            <FontColor>
              <FourGround>
                <div className="collectorTitle d-flex justify-content-between px-4">
                  <div className="component_name">{t("device")}</div>

                  <div className="d-flex justify-content-center align-items-center">
                    <div className="reloadBtn">
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <ArrowClockwise
                          className="arrowClock"
                          title={t("refresh")}
                          onClick={deviceReloadHandler}
                        />
                      )}
                    </div>

                    <div
                      onClick={() => {
                        setModalStatus("addDevice");
                        setIsUpdate(false);
                        setShow(true);
                      }}
                    >
                      <Plus className="addcutmButton" />
                    </div>
                  </div>
                </div>
              </FourGround>
              <FourGround>
                <div className="collectorWrapper mt-2 py-2">
                  <Table
                    isLoading={isLoading}
                    columns={columns}
                    data={devices}
                  ></Table>
                </div>
              </FourGround>
              <Footer />
            </FontColor>
          </div>
        </div>
      </div>

      {/* Mikrotik Router create modal */}
      {modalStatus === "addDevice" && (
        <DeviceForm
          {...{
            show,
            setShow,
            title: isUpdate
              ? `${t("update")} ${t("mikrotik")}`
              : `${t("create")} ${t("mikrotik")}`,
          }}
        />
      )}
    </>
  );
};

export default Device;
