import React, { useEffect, useRef, useMemo, useState } from "react";
import * as d3 from "d3";
import Sidebar from "../../../components/admin/sidebar/Sidebar";
import { ToastContainer } from "react-toastify";
import useDash from "../../../assets/css/dash.module.css";
import { FontColor, FourGround } from "../../../assets/js/theme";
import { useTranslation } from "react-i18next";
import AssignDevice from "./AssignDevice";
import Footer from "../../../components/admin/footer/Footer";
import useISPowner from "../../../hooks/useISPOwner";
import { useDispatch, useSelector } from "react-redux";
import { getNetworkDiagramDevice } from "../../../features/apiCalls";

const Diagram = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const svgRef = useRef(null); // Ref for the SVG element
  const gRef = useRef(null); // Ref for the g element to apply zoom and pan

  // get network devices diagram form redux store
  const diagrams = useSelector((state) => state.network?.diagram);
  console.log(diagrams);

  // Loading state
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [assignData, setAssignData] = useState(null);

  // get user & current user data form useISPOwner hooks
  const { ispOwnerId } = useISPowner();

  // Memoize the data object
  const data = useMemo(() => {
    const formattedData = {
      children: diagrams?.children?.map((child) => ({
        name: child.candidateType, // Candidate type or other info
        details: child, // Additional details you may want to pass
      })),
    };

    return formattedData;
  }, [diagrams]);

  useEffect(() => {
    getNetworkDiagramDevice(dispatch, ispOwnerId, setIsLoading);
  }, []);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const g = d3.select(gRef.current);

    // Set up the chart with dynamic height and width
    const width = 1000;
    const margin = { top: 10, right: 10, bottom: 10, left: 40 };
    const dx = 20;
    const root = d3.hierarchy(data);
    const dy = (width - margin.left - margin.right) / (root.height + 1);

    svg
      .attr("viewBox", [-margin.left, -margin.top, width, dx])
      .attr("style", "height: 85vh; font: 15px sans-serif; user-select: none;");

    const gLink = g
      .append("g")
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-opacity", 0.4)
      .attr("stroke-width", 1.5);

    const gNode = g
      .append("g")
      .attr("cursor", "pointer")
      .attr("pointer-events", "all");

    const tree = d3.tree().nodeSize([dx, dy]);
    const diagonal = d3
      .linkHorizontal()
      .x((d) => d.y)
      .y((d) => d.x);

    // Zoom and pan behavior
    const zoom = d3.zoom().on("zoom", (event) => {
      g.attr("transform", event.transform);
    });

    svg.call(zoom);

    function update(source) {
      const nodes = root.descendants().reverse();
      const links = root.links();

      // Layout the nodes
      tree(root);

      // Update dynamic height
      const height =
        root.descendants().reduce((max, d) => Math.max(max, d.x), 0) +
        margin.top +
        margin.bottom;

      // Update the SVG height based on the content
      svg.transition().duration(500).attr("height", height);

      // Nodes update
      const node = gNode.selectAll("g").data(nodes, (d) => d.id);

      // Nodes update
      const nodeEnter = node
        .enter()
        .append("g")
        .attr("transform", (d) => `translate(${source.y0},${source.x0})`)
        .on("click", (event, d) => {
          // Toggle children on click
          d.children = d.children ? null : d._children;
          update(d); // Trigger update when children are toggled
        })
        .on("contextmenu", (event, d) => {
          event.preventDefault(); // Prevent default right-click menu
          setAssignData(d.data); // Set the clicked node data
          setShow(true); // Open the modal
        });

      // Append circle to represent nodes
      nodeEnter
        .append("circle")
        .attr("r", 5) // Adjust size of the circle
        .attr("fill", (d) => (d._children ? "#555" : "#999"))
        .attr("stroke-width", 2)
        .attr("stroke", "#000");

      // Append text to represent node labels
      nodeEnter
        .append("text")
        .attr("dy", "0.31em")
        .attr("x", (d) => (d._children ? -10 : 10))
        .attr("text-anchor", (d) => (d._children ? "end" : "start"))
        .attr("fill", "#333")
        .attr("font-size", "12px")
        .attr("font-weight", "bold")
        .text((d) => d.data.name)
        .style("cursor", "pointer")
        .append("title") // Add tooltip to text element
        .text(
          (d) =>
            `Left-click: ${d.data.name} children\nRight-click: ${d.data.name} assign device`
        );

      // Transition the position of nodes
      node
        .merge(nodeEnter)
        .transition()
        .duration(500)
        .attr("transform", (d) => `translate(${d.y},${d.x})`);

      node
        .exit()
        .transition()
        .remove()
        .attr("transform", (d) => `translate(${source.y},${source.x})`);

      // Links update
      const link = gLink.selectAll("path").data(links, (d) => d.target.id);

      const linkEnter = link
        .enter()
        .append("path")
        .attr("d", (d) => {
          const o = { x: source.x0, y: source.y0 };
          return diagonal({ source: o, target: o });
        });

      link.merge(linkEnter).transition().duration(500).attr("d", diagonal);

      link
        .exit()
        .transition()
        .remove()
        .attr("d", (d) => {
          const o = { x: source.x, y: source.y };
          return diagonal({ source: o, target: o });
        });

      // Store the old positions for the transition
      root.eachBefore((d) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    root.x0 = dx / 2;
    root.y0 = 0;

    root.descendants().forEach((d, i) => {
      d.id = i;
      d._children = d.children;
      if (d.depth && d.data.name.length !== 7) d.children = null;
    });

    update(root);
  }, [data]);

  return (
    <>
      <Sidebar />
      <ToastContainer position="top-right" theme="colored" />
      <div className={useDash.dashboardWrapper}>
        <div className="container-fluid collector">
          <div className="container">
            <FontColor>
              <FourGround>
                <div className="collectorTitle d-flex justify-content-between px-4">
                  <div className="component_name">{`${t("network")} ${t(
                    "diagram"
                  )}`}</div>
                </div>
              </FourGround>
              <FourGround>
                <div className="mt-2">
                  <div
                    style={{
                      overflow: "auto",
                      maxHeight: "100vh",
                      maxWidth: "200%",
                    }}
                  >
                    <svg ref={svgRef} width="100%" height="800">
                      <g ref={gRef}></g> {/* Use gRef to target the inner g */}
                    </svg>
                  </div>
                </div>
              </FourGround>
              <Footer />
            </FontColor>
          </div>
        </div>
      </div>

      {/* Modal to show when a node is right-clicked */}
      <AssignDevice show={show} setShow={setShow} data={assignData} />
    </>
  );
};

export default Diagram;
